<template>
    <div class="contact-form-panel" part="vue-contact-form-panel">
        <div v-if="isSubmitted">
            <div class="row" part="vue-form-row">
                <div class="w-full text-left p-6" part="vue-form-html-text">

                    <h3 class="mb-4 text-red font-avenir font-extrabold uppercase text-2xl" part="vue-form-response-header">Thank You for Contacting MMR</h3>

                    <p class="mb-4">Your message has been sent. We will go over it and get back in touch with you shortly.</p>

                    <p class="text-lg" part="text-lg">
                        <a href="#" class="btn-text" part="btn-text" @click.prevent="reload()">Return to the Contact Form</a>
                    </p>

                </div>
            </div>
        </div>
        <div v-else>
            <div class="row" part="vue-form-row">
                <div class="px-6 py-3 w-full" part="vue-form-full-column">
                    <label part="vue-form-label" >Are you a(n)?</label>
                    <select class="form-control" part="form-control-select" v-model.lazy.trim="userGroup" @change="groupCheck">
                        <option value="null" selected>Select One...</option>
                        <option value="1">Employee</option>
                        <option value="2">Job Seeker</option>
                        <option value="3">Client/Business Owner</option>
                        <option value="4">Vendor/Supplier</option>
                        <option value="5">California resident with a personal information request</option>
                        <option value="5">European resident with a personal information request</option>
                        <option value="6">Other</option>
                    </select>
                </div>
            </div>

            <div v-if="userGroup != null && userGroup != 2 && userGroup != 3" class="row" part="vue-form-row">
                <div class="px-6 py-3 w-full" part="vue-form-full-column">
                    <label part="vue-form-label" >What is the Nature of Your Inquiry?</label>
                    <select class="form-control" part="form-control-select" v-model.lazy.trim="department" @change="setType($event)">
                        <option value="null" selected>Select One...</option>
                        <option v-if="userGroup == 1" value="a">Questions about Employment Dates</option>
                        <option v-if="userGroup == 1" value="b">Employment Verification</option>
                        <option v-if="userGroup == 1" value="c">Verification of Hours for Licensing</option>
                        <option v-if="userGroup == 1" value="b">Copy of Paycheck</option>
                        <option v-if="userGroup == 1" value="b">Copy of W-2</option>
                        <option v-if="userGroup == 1" value="b">Change of Address on File</option>
                        <option v-if="userGroup == 1" value="d">Garnishments</option>
                        <option v-if="userGroup == 1" value="e">Unemployment</option>
                        <option v-if="userGroup == 1" value="f">Health Insurance</option>
                        <option v-if="userGroup == 1" value="g">Leave (Vacation/Sick)</option>
                        <option v-if="userGroup == 1" value="m">401K/Profit Sharing</option>
                        <option v-if="userGroup == 4" value="h">Becoming a Vendor/Supplier</option>
                        <option v-if="userGroup == 4" value="i">Invoices & Payment</option>
                        <option v-if="userGroup == 5" value="l">Export My Personal Info</option>
                        <option v-if="userGroup == 5" value="l">Erase My Personal Info</option>
                        <option v-if="userGroup == 5" value="l">Other</option>
                        <option v-if="userGroup == 6" value="j">Community Outreach/Donation Request</option>
                        <option v-if="userGroup == 6" value="k">General Questions/Inquiry</option>
                    </select>
                    <div v-if="isErrorField('recipients')" class="error-messages" part="error-message" >{{ 'This field is invalid or missing.', { field: 'recipients' } }}</div>
                </div>
            </div>
            <div v-if="userGroup == 2">
                <div class="row" part="vue-form-row">
                    <div class="w-full text-left p-6" part="vue-form-html-text">
                        <p>If you are interested in a position at MMR, <a href="/workforce-development/careers" part="textlink"><b>please visit our careers section.</b></a></p>
                        <p>You may also contact one of <a href="/locations" part="textlink"><b>MMR&rsquo;s District Offices</b></a> to schedule an appointment.</p>
                        <p>These items are required to complete an employment application:</p>
                        <ul class="bullet-list" part="bullet-list">
                            <li>2 forms of government (state or federal) identification</li>
                            <li>TWIC card if required</li>
                            <li>Void check or bank set-up form for direct deposit</li>
                            <li>Social Security Card</li>
                            <li>Spouse and dependents date of birth and social security number if elect to enroll in the insurance program</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="department != null">
                <div v-if="userGroup != 2">
                    <div v-if="department == 'j'">
                        <div class="row" part="vue-form-row">
                            <div class="w-full text-left p-6" part="vue-form-html-text">
                                <p>If you are looking for MMR&rsquo;s support in a community outreach effort or for MMR to make a donation, <a href="/about-mmr/philanthropy" part="textlink"><b>please fill out our donation request form.</b></a></p>
                            </div>
                        </div>
                    </div>

                    <div v-if="department == 'g'">
                        <div class="row" part="vue-form-row">
                            <div class="w-full text-left p-6" part="vue-form-html-text">
                                <p>Please report to your immediate supervisor for information.</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="department != 'j' && department != 'g' && department != null">

                        <form @submit.prevent="submit" method="post" novalidate>

                            <div class="row" part="vue-form-row">
                                <div class="px-6 py-3 w-full" part="vue-form-full-column">
                                    <p v-if="userGroup == 3">Ready to work with the industry leader? Inquire about MMR's services to meet your project needs.</p>
                                    <p v-if="userGroup != 3">Please fill out the form below.</p>
                                    <p>* Required Field</p>
                                </div>
                            </div>

                            <div class="row" part="vue-form-row">
                                <div class="px-6 py-3 w-full md:w-1/2" part="vue-form-half-column">
                                    <label part="vue-form-label"  for="firstName">First Name*</label>
                                    <input type="text" class="form-control" part="form-control" id="firstName" v-model.lazy.trim="form.firstName" @blur="onFieldBlur('firstName')" v-bind:class="getFieldClasses('firstName')">
                                    <div v-if="v$.form.firstName.$error" class="error-messages" part="error-message" >{{ 'This field is required.', { field: 'First Name' } }}</div>
                                </div>

                                <div class="px-6 py-3 w-full md:w-1/2" part="vue-form-half-column">
                                    <label part="vue-form-label"  for="lastName">Last Name*</label>
                                    <input type="text" class="form-control" part="form-control" id="lastName" v-model.lazy.trim="form.lastName" @blur="onFieldBlur('lastName')" v-bind:class="getFieldClasses('lastName')">
                                    <div v-if="v$.form.lastName.$error" class="error-messages" part="error-message" >{{ 'This field is required.', { field: 'Last Name' } }}</div>
                                </div>
                            </div>

                            <div class="row" part="vue-form-row" v-if="userGroup != 1 && userGroup != 2 && userGroup != 5">
                                <div class="px-6 py-3 w-full" part="vue-form-full-column">
                                    <label part="vue-form-label"  for="companyName">Company Name<span v-if="userGroup == 3 || userGroup == 4">*</span></label>
                                    <input type="text" class="form-control" part="form-control" id="companyName" v-model.lazy.trim="form.companyName" @blur="onFieldBlur('companyName')" v-bind:class="getFieldClasses('companyName')">
                                    <div v-if="v$.form.companyName.$error" class="error-messages" part="error-message" >{{ 'This field is required.', { field: 'Company Name' } }}</div>
                                </div>
                            </div>

                            <div class="row" part="vue-form-row">
                                <div class="px-6 py-3 w-full md:w-1/2" part="vue-form-half-column">
                                    <label part="vue-form-label"  for="email">Email Address*</label>
                                    <input type="email" class="form-control" part="form-control" id="email" v-model.lazy.trim="form.email" @blur="onFieldBlur('email')" v-bind:class="getFieldClasses('email')">
                                    <div v-if="v$.form.email.$error" class="error-messages" part="error-message" >{{ 'This field is invalid or missing.', { field: 'Email Address' } }}</div>
                                </div>

                                <div class="px-6 py-3 w-full md:w-1/2" part="vue-form-half-column">
                                    <label part="vue-form-label"  for="phone">Phone Number*</label>
                                    <input type="text" class="form-control" part="form-control" id="phone" v-model.lazy.trim="form.phone" @blur="onFieldBlur('phone')" v-bind:class="getFieldClasses('phone')">
                                    <div v-if="v$.form.phone.$error" class="error-messages" part="error-message" >{{ 'This field is required.', { field: 'Phone Number' } }}</div>
                                </div>
                            </div>

                            <div class="row" part="vue-form-row">
                                <div class="px-6 py-3 w-full" part="vue-form-full-column">
                                    <label part="vue-form-label"  for="message">Message*</label>
                                    <textarea
                                            type="message"
                                            class="form-control"
                                            part="form-control-textarea"
                                            id="message"
                                            v-model.trim="form.message"
                                            v-bind:class="getFieldClasses('message')"
                                            @blur="onFieldBlur('message')">
                                    </textarea>
                                    <div v-if="v$.form.message.$error" class="error-messages" part="error-message" >{{ 'This field is invalid or missing.', { field: 'Message'} }}</div>
                                </div>
                            </div>
                            <div class="row" part="vue-form-row">
                                <div class="px-6 py-3 w-full text-lg" part="vue-form-btn-wrapper">
                                    <button class="btn-arrow" part="vue-form-btn" :disabled="submitting">
                                        Submit                                        
                                    </button>
                                    <div v-if="submitting" class="px-1 pt-2">
                                        Submitting form, please wait.</div>
                                    <p v-if="isError" class="pt-3 px-1 text-red-light font-bold" part="vue-form-error">Your submission has errors in it. Please review the form and try again.</p>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators';
    import axios from 'axios';
    import "@css/app.pcss";
    //import {mask} from 'vue-the-mask';
    //import { InputFacade, facade, filter } from 'vue-input-facade';

    const emailList = {
        'a': ['employmentdates@mmrgrp.com'],
        'b': ['employmentrecords@mmrgrp.com'],
        'c': ['licensinghours@mmrgrp.com'],
        'd': ['garnishments@mmrgrp.com'],
        'e': ['unemployment@mmrgrp.com'],
        'f': ['healthinsurance@mmrgrp.com'],
        'h': ['vendors@mmrgrp.com'],
        'i': ['invoices@mmrgrp.com'],
        'k': ['webinquiry@mmrgrp.com'],
        'l': ['privacyquestions@mmrgrp.com'],
        'm': ['profitsharing@mmrgrp.com']
    }

    export default {
        name: 'contact-form',
        data() {
            return {
                userGroup: null,
                department: null,
                isSubmitted: false,
                isError: false,
                errorHeader: 'error.invalidFields',
                errors: [],
                submitting: false,
                form: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    companyName: '',
                    // FIX - You only pass one email so this does not need to be an array. FF email field type expects a string, not an array as per docs
                    // 'departmentRecipient[]': '',
                    departmentRecipient: '',
                    phone: '',
                    message: '',
                    formHash: '',
                    freeform_payload: '',                    
                    requestType: '',
                    CRAFT_CSRF_TOKEN: '',
                    
                }
            }
        },
        
        setup () {
            return {
              v$: useVuelidate()
            }
        },
        
        created: function () {
            // `this` points to the vm instance
            const self = this;

            axios.get('/resources/contactFormFixer')
            .then(function (response) {// handle success
                self.form.formHash = response.data.hash.trim();
                self.form.freeform_payload = response.data.payload.trim();
                //self.form.csrfName = response.data.csrf.name.trim();
                self.form.CRAFT_CSRF_TOKEN = response.data.csrf.value.trim();
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
            
            /*
            function getFormHash() {
                return axios.get('/forms/formhashgetter/contactForm');
            }

            function getHptName() {
                return axios.get('/forms/hptnamegetter/contactForm');
            }

            function getHptHash() {
                return axios.get('/forms/hpthashgetter/contactForm');
            }

            axios.all([getFormHash(), getHptName(), getHptHash()])
                .then(axios.spread(function (fHash, hName, hHash) {

                    // Both requests are now complete
                    self.form.formHash = fHash.data.trim();
                    self.form.hptName = hName.data.trim();
                    self.form.hptHash = hHash.data.trim();
                    console.log(self.form.formHash);
                    console.log(self.form.hptName);
                    console.log(self.form.hptHash);
                }));
            */
        },
        
        methods: {
            groupCheck() {
                if (this.userGroup == 3) {
                    this.department = 'k';
                } else {
                    if (this.userGroup != 2) {
                        this.department = null;
                    }
                }
            },
            setType(event) {
                const sel = event.target;
                this.form.requestType = sel.options[sel.selectedIndex].text;
            },
            async submit() {                
                const isFormCorrect = await this.v$.$validate()
                if (isFormCorrect) {
                    this.sendFormData();
                    console.log('submitted');
                } else {
                    this.validationError();
                }                               
            },
            enableSubmitLoader() {
                this.submitting = true;
            },
            disableSubmitLoader() {
                this.submitting = false;
            },
            
            sendFormData() {
                this.enableSubmitLoader();
                let formData = new FormData();
                let formInput = this.form;

                // FIX 1 - You do not need to append these as they are getting appended below
                //formData.append('action', 'freeform/api/form');
                //formData.append('CRAFT_CSRF_TOKEN', this.form.CRAFT_CSRF_TOKEN);
                //formData.append('formHash', this.form.formHash);
                //formData.append('freeform_payload', this.form.freeform_payload);

                // FIX 2 - You only pass one email so this does not need to be an array. FF email field type expects a string, not an array as per docs
                //this.form['departmentRecipient[]'] = emailList[this.department];
                this.form['departmentRecipient'] = emailList[this.department];

                //this.form.dynamicNotification.recipients = emailList[this.department];
                Object.keys(formInput).forEach(function (item) {
                    formData.append(item, formInput[item]);
                });

                // FIX 3 - We need to use formData instead of stringify this.form
                //let formDataString = JSON.stringify(this.form);
                //console.log(formDataString);

                // How to display the key/value pairs in formData
                for (let pair of formData.entries()) {
                  console.log(pair[0]+ ', ' + pair[1]);
                }

                // FIX 4 - Swap formData for formDataString. Axios will handle the rest.
                axios.post('/actions/freeform/submit', formData, {
                    headers: {
                        'X-CSRF-Token': this.form.CRAFT_CSRF_TOKEN,
                        "X-Requested-With": "XMLHttpRequest",
                        "HTTP_X_REQUESTED_WITH": "XMLHttpRequest",

                    }
                }).then(response => {
                    this.submitSuccess(response);
                    this.disableSubmitLoader();
                }).catch(error => {
                    this.submitError(error);
                    this.disableSubmitLoader();
                });

            },
            
            submitSuccess(response) {
                if (response.data.success) {
                    this.isSubmitted = true;
                    this.isError = false;
                } else {
                    this.errorHeader = 'error.invalidFields';
                    this.errors = response.data.errors;
                    this.isError = true;
                }
            },
            submitError(error) {
                this.errorHeader = 'error.general';
                this.errors = [{'field': null, 'message': 'error.generalMessage'}];
                this.isError = true;
            },
            validationError() {
                this.errorHeader = 'error.invalidFields';
                this.errors = this.getErrors();
                this.isError = true;
            },
            isErrorField(field) {
                try {
                    if (this.getValidationField(field).$error) {
                        return true;
                    }
                } catch (error) {}

                return this.errors.some(el => el.field === field);
            },
            getErrors() {
                let errors = [];
                for (const field of Object.keys(this.form)) {
                    try {
                        if (this.getValidationField(field).$error) {
                            errors.push({'field': field, 'message': null});
                        }
                    } catch (error) {}
                }
                return errors;
            },
            getFieldClasses(field) {
                return { 'is-invalid': this.isErrorField(field) }
            },
            getValidationField(field) {                
                if ($v.form[field]) {
                    return $v.form[field];
                }
                throw Error('No validation for field ' + field);
            },
            onFieldBlur(field) {
                try {
                    this.getValidationField(field).$touch();
                    if (this.getValidationField(field).$error) {
                        if (!this.errors.some(el => el.field === field)) {
                            this.errors.push({'field': field, 'message': null});
                        }
                    } else {
                        this.errors = this.errors.filter(el => el.field !== field);
                    }
                } catch (error) {}
            },
            reload() {
                window.location = '';
            }
        },
        validations() {
            if (this.userGroup == 3 || this.userGroup == 4 ) {
                return {
                    form: {
                        email: { required, email },
                        firstName: { required },
                        lastName: { required },
                        companyName: { required },
                        phone: { required },
                        message: { required }
                    }
                }
            } else {
                return {
                    form: {
                        email: { required, email },
                        firstName: { required },
                        lastName: { required },
                        phone: { required },
                        message: { required }
                    }
                }
            }
        },
        watch: {
            errors() {
                this.isError = this.errors.length > 0 ? true : false;
            }
        }
    }
</script>
